import { jsx } from '@emotion/core';



const ContactForm = () => (
  <form
    css={[{ display: "block" }, { marginBottom: 230 }]}
    name="contact"
    method="post"
    netlify
    data-netlify="true"
    netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value="contact" />
    <input
      css={[
        { color: "darkorchid" },
        { backgroundColor: "white" },
        { padding: 8 },
        { width: "100%" },
        { maxWidth: 600 },
        { minHeight: 37 },
        { borderColor: "black" },
        { border: 1 },
        { display: "block" },
        { outline: "none" },
      ]}
      type="text"
      name="name"
      placeholder="What's your name"
      id="name"
    />
    <input
      css={[
        { color: "darkorchid" },
        { backgroundColor: "white" },
        { padding: 8 },
        { width: "100%" },
        { maxWidth: 600 },
        { minHeight: 37 },
        { display: "block" },
        { borderColor: "black" },
        { border: 1 },
        { marginTop: 20 },
        { outline: "none" },
      ]}
      type="email"
      name="email"
      placeholder="Your email"
      id="email"
    />
    <textarea
      css={[
        { color: "darkorchid" },
        { backgroundColor: "white" },
        { padding: 8 },
        { width: "100%" },
        { maxWidth: 600 },
        { minHeight: 37 },
        { display: "block" },
        { borderColor: "black" },
        { border: 1 },
        { marginTop: 20 },
        { outline: "none" },
      ]}
      name="message"
      placeholder="How can we help?"
      id="message"
      rows="5"
    />
    <button
      css={[
        { marginTop: 20 },
        { width: 100 },
        { height: 50 },
        { borderColor: "gray" },
        { backgroundColor: "#ffffff" },
        { borderLeftColor: "#000000" },
        { border: 1 },
        { borderTopLeftRadius: 25 },
        { borderTopRightRadius: 25 },
        { borderBottomLeftRadius: 25 },
        { borderBottomRightRadius: 25 },
        { outline: "none" },
      ]}
      type="submit"
    >
      Send
    </button>
  </form>
);

export default ContactForm