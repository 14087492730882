import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Who we are`}</h2>
    <blockquote>
      <blockquote parentName="blockquote">
        <p parentName="blockquote">{`AEOU development works with companies throughout the United States with a focus on the New York City region. `}</p>
      </blockquote>
    </blockquote>
    <p>{`We leverage over 30 years of combined experience to provide leadership, expertise and development resources to companies all over the New York region and the nation.`}</p>
    <p>{`Most recently, we've been focused on Web Development, Technical consultation, Small - Medium AI integration, Cloud Architecture and Cloud solutions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      