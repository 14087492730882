import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ServiceCard = makeShortcode("ServiceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What we do`}</h2>
    <ServiceCard title="Web Development" link="https://aeou.dev" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ServiceCard">
  Weather it be Single Page Applications, Progressive Web Applications, React development or CMS integration
  we've dealt with it.<br /><br />
  PWA's, SPA's, Ux/UI/CMS
    </ServiceCard>
    <ServiceCard title="Technical consultation" link="https://www.aeou.dev" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ServiceCard">
  Digital Transformation<br />
  Containerization
    </ServiceCard>
    <ServiceCard title="Machine Learning / Artificial Intelligence" link="https://aeou.dev" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ServiceCard">
  Small - Medium ... AI Integration<br />
  RPA (Robotic Process Automation)<br />
  CI/CD integration
    </ServiceCard>
    <ServiceCard title="Cloud Architecture" link="https://aeou.dev" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ServiceCard">
  Cloud solutions.<br />
  Security, Authentications, <br />CIAM (Customer Identity & Access Management)
    </ServiceCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      